body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button.headerFloatingBtn {
  background-repeat: no-repeat;
  background-size: 105px;
  background-position: -37px -32px;
  width: 30px;
  height: 30px;
  padding: 0;
  box-shadow: 0 0 0 rgb(204 169 44 / 40%);
  animation: headerFloatingBtn 2s infinite;
  -webkit-animation: headerFloatingBtn 2s infinite;
}

button.headerFloatingBtn :hover {
  animation: none;
}

@-webkit-keyframes headerFloatingBtn {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes headerFloatingBtn {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

.inputRounded .MuiOutlinedInput-root {
  border-radius: 50px;
  font-weight: 400;
  max-height: 60px;
  min-height: 20px;
  outline: none;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  white-space: pre-wrap;
  word-wrap: break-word;
  z-index: 1;
  width: 100%;
  user-select: text;
  padding: 9px 12px 11px;
  text-align: left;
}

body {
  background-color: #eef2f7 !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#toast-container > div {
  opacity: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

Header {
  box-shadow: none !important;
}

.notiZindex {
  position: relative;
  z-index: 100;
}

.smestProfile .activeMenuItem span {
  color: #ec4f56 !important;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}
.mainChangePassword,
.mainConfigSetting,
.mainLogin,
.mainSignUP {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
/* ==============Grid Css Start========================= */
.ordersDataGird.Rehan .MuiDataGrid-row .MuiDataGrid-cell {
  border-top: 1px solid rgba(224, 224, 224, 1);
}
/* ==============Grid Css End========================= */
.smestProfile .activeMenuItem {
  background: #ec4f5614 !important;
  border: 0 !important;
}

.smestProfile .MuiListItemIcon-root {
  min-width: 0;
}
.pageTitle {
  padding: 20px 30px;
}
.MuiGrid-root.profileshowConntan {
  width: 80%;
  margin: 37px auto;
  padding: 15px 25px !important;
}
.profileViewImgBox,
.profileshowConntan {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #a1a0a0;
  border-radius: 10px;
}

.profileshowConntan {
  transition: 1s ease;
  -webkit-transition: 1s ease;
  -moz-transition: 1s ease;
  -ms-transition: 1s ease;
  -o-transition: 1s ease;
}

.profileshowConntan {
  /* min-height: 100%; */
  padding: 30px 20px !important;
  overflow: unset;
}

.profileshowConntan .MuiGrid-container {
  margin-bottom: 30px;
}

.MuiGrid-root.editUserDetailsDialogSection {
  width: 50%;
  margin: 37px auto;
  padding: 15px 25px;
}
.profileViewImgBox,
.editUserDetailsDialogSection {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #a1a0a0;
  border-radius: 10px;
  min-height: 545px;
}

.editUserDetailsDialogSection {
  transition: 1s ease;
  -webkit-transition: 1s ease;
  -moz-transition: 1s ease;
  -ms-transition: 1s ease;
  -o-transition: 1s ease;
}

.editUserDetailsDialogSection {
  min-height: 1600px;
  padding: 30px 20px;
  overflow: hidden;
}

.editUserDetailsDialogSection .MuiGrid-container {
  margin-bottom: 30px;
}

.MuiGrid-root.nomineeDetailsContainer {
  width: 80%;
  margin: 37px auto;
  padding: 15px 25px;
}

.profileViewImgBox,
.nomineeDetailsContainer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #a1a0a0;
  border-radius: 10px;
  min-height: 445px;
}

.nomineeDetailsContainer {
  transition: 1s ease;
  -webkit-transition: 1s ease;
  -moz-transition: 1s ease;
  -ms-transition: 1s ease;
  -o-transition: 1s ease;
}

.profileViewImgBox {
  min-height: 445px;
  width: 100%;
  position: sticky;
  top: 15px;
  transition: 1500ms all;
  -webkit-transition: 1500ms all;
  -moz-transition: 1500ms all;
  -ms-transition: 1500ms all;
  -o-transition: 1500ms all;
}

.userProfileDetailsCard {
  min-height: 400px;
  padding: 30px 20px;
  overflow: hidden;
}
.MuiGrid-root.userProfileDetailsCard {
  width: 80%;
  margin: 37px auto;
  padding: 15px 25px;
}

.userProfileDetailsCard {
  transition: 1s ease;
  -webkit-transition: 1s ease;
  -moz-transition: 1s ease;
  -ms-transition: 1s ease;
  -o-transition: 1s ease;
}

.userProfileDetailsCard .MuiGrid-container {
  margin-bottom: 30px;
}

.profileViewImgBox,
.userProfileDetailsCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #a1a0a0;
  border-radius: 10px;
  min-height: 445px;
}

.nomineeDetailsContainer {
  min-height: 328px;
  padding: 30px 20px;
  overflow: hidden;
}

.nomineeDetailsContainer .MuiGrid-container {
  margin-bottom: 30px;
}

.profileInfoDate {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  position: relative;
}

.editUserDetailsDate {
  padding-bottom: 10px;
  position: relative;
}

.profileInfoDateTitle {
  margin-bottom: 5px;
  font: normal normal 14px/21px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
  opacity: 0.5;
}

.profileInfoDate h6 {
  font: normal normal 600 16px/21px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
  opacity: 1;
  padding-left: 10px;
  text-transform: capitalize;
}

.profileInfoDate .profileEditButton {
  position: absolute;
  right: 0;
  bottom: 0;
  transition: 300ms all;
  -webkit-transition: 300ms all;
  -moz-transition: 300ms all;
  -ms-transition: 300ms all;
  -o-transition: 300ms all;
  transform: scale(0);
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
}

.profileInfoDate:hover .profileEditButton {
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}

.contantAudioplayer h6 {
  font: normal normal bold 14px/24px Raleway;
  letter-spacing: 0px;
  color: #2a324c;
  margin: 0;
}

.contantAudioplayer p {
  font: normal normal normal 14px/26px Raleway;
  letter-spacing: 0px;
  color: #2a324c;
  opacity: 0.5;
  margin: 0;
}

.logoImgMB {
  position: absolute;
  height: 100%;
  left: 20px;
  top: 20px;
}

.controlAudioplayer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 25px 0 8px 0;
  background: #ffffff;
  box-shadow: 0px 10px 20px #2a324c1a;
  border-radius: 15px;
  margin-bottom: 20px;
}

.playBtn {
  position: relative;
}

.playBtn::after {
  position: absolute;
  content: "";
  border: 0;
  background: transparent;
  box-sizing: border-box;
  width: 0;
  border-color: transparent transparent transparent #e11717;
  transition: 100ms all ease;
  cursor: pointer;
  border-style: solid;
  border-width: 8px 0 8px 12px;
  left: 52%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.audioPlayerViewBox {
  position: relative;
  width: 50%;
  flex: 0 0 auto;
}

.audioPlayerViewBox .audio-player {
  width: 100% !important;
  justify-content: space-between;
  min-height: 30px;
  align-items: start;
}

.audioPlayerViewBox #loop,
.audioPlayerViewBox .audio-player-track-name,
.audioPlayerViewBox .audio-player-volume {
  display: none !important;
}

.audioPlayerViewBox #play,
.audioPlayerViewBox #pause,
.audioPlayerViewBox #volume,
.audioPlayerViewBox #forward,
.audioPlayerViewBox #rewind {
  background-color: transparent;
  cursor: pointer;
  flex: 0 0 auto;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.audioPlayerViewBox #play {
  order: 2;
  background-color: #ffe8e8;
  border-radius: 50%;
}

.audioPlayerViewBox #rewind {
  order: 1;
}

.audioPlayerViewBox #forward {
  order: 3;
}

.audioPlayerViewBox .audio-player-time {
  position: absolute;
  bottom: 0;
  left: 2px;
}

.audioPlayerViewBox .audio-player-seeker {
  flex: 0 0 auto;
  width: 40% !important;
  order: 0;
  margin-top: 5px;
}

.audioPlayerViewBox input[type="range"].slider::-webkit-slider-runnable-track {
  background-color: #2a324c;
  border: none;
  box-shadow: none;
}

.audioPlayerViewBox input[type="range"].slider::-webkit-slider-thumb {
  box-shadow: none;
  border: 2px solid #2a324c;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: rgb(255 255 255);
  margin-top: -5.5px;
}

.MuiCard-root.same-height {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.rwdPortfolioAllocation {
  background: #e8e8e8 0% 0% no-repeat padding-box;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  margin-top: 10px;
  padding: 15px;
}

.rwdPortfolioAllocation
  p.MuiTypography-root.MuiTypography-body1.MuiTypography-noWrap {
  font: normal normal normal 12px/18px Lexend;
  letter-spacing: 0px;
  color: #9498a5;
}

.MuiGrid-root.rwdPortfolioAllocation.rwdPortfolioHoldings {
  padding: 15px 15px 100px;
}

.graphBoxSec {
  padding: 15px 0;
}

.graphBoxSec .graphBox {
  min-height: 185px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #2a324c1a;
  border-radius: 20px;
  margin-bottom: 10px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.graphBoxSec h6.MuiTypography-root.MuiTypography-h6.MuiTypography-noWrap {
  font: normal normal 12px/18px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
  padding: 10px 0px 0px 10px;
}

.lookBg {
  display: flex;
  background: rgb(200 200 200 / 78%);
  border-radius: 15px;
  position: absolute;
  /* opacity: 0.2; */
  z-index: 2;
  height: 100%;
  width: 100%;
  left: 0;
  bottom: 0;
  top: 0;
  backdrop-filter: blur(-13px);
  align-items: center;
  justify-content: center;
}

.rwdDashboard .cardViewAmu {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 10px 10px 0;
  margin-bottom: 10px;
  min-height: 70px;
}

.rwdDashboard .cardViewAmu .cardInfoAmu {
  display: flex;
  align-items: center;
}

.rwdDashboard
  .cardViewAmu
  .cardInfoAmu
  h5.MuiTypography-root.MuiTypography-h5.MuiTypography-noWrap {
  font: normal normal bold 16px/24px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
  margin: 0 10px;
}

.rwdDashboard
  .cardViewAmu
  .cardInfoAmu
  p.MuiTypography-root.MuiTypography-body1.MuiTypography-noWrap {
  font: normal normal normal 12px/18px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
}

.smestDashboard,
.smestManageFunds,
.referAndEaenSec,
.helpAndSupport {
  padding: 20px 30px 30px;
}

.smestDashboard,
.smestManageFunds,
.referAndEaenSec,
.helpAndSupport {
  padding: 20px 25px 30px;
}

.dashBoardCardView {
  overflow: hidden;
  margin-bottom: 20px;
}

.dashCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  min-height: 100px;
  min-width: 150px;
  position: relative;
  padding: 20px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation: dashCard 1.5s ease;
  -webkit-animation: dashCard 1.5s ease;
  animation-delay: 1s;
}

.portfolioAllocation {
  background: #e8e8e8 0% 0% no-repeat padding-box;
  min-height: 325px;
  padding: 15px 25px 25px;
  border-radius: 20px;
}

portfolioAllocation h6.MuiTypography-root.MuiTypography-h6.MuiTypography-noWrap,
.orderHistory h5.MuiTypography-root.MuiTypography-h5.MuiTypography-noWrap,
.portfolioHoldings h5.MuiTypography-root.MuiTypography-h5.MuiTypography-noWrap {
  font: normal normal 12px/18px Lexend;
  letter-spacing: 0px;
  color: #9498a5;
  margin-bottom: 12px;
}

.portfolioAllocationValue {
  padding: 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  min-height: 257px;
  box-shadow: 0px 6px 12px #2a324c1a;
  border-radius: 20px;
}

.portfolioAllocationValue
  h6.MuiTypography-root.MuiTypography-h6.MuiTypography-noWrap {
  font: normal normal 12px/18px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
  margin: 0;
}

.textColorClass {
  color: #a9a9a9;
  font-weight: 500;
  font-size: 13px;
}

.corpCompMainContainer {
  height: 100%;
}

.corpCompMainContainer .recentTransactions {
  background-color: #fff;
  padding: 0;
}

.corpCompMainContainer .recentTransactions .gridComTabel.tableHade {
  margin: 0 0 10px;
}

.corpCompMainContainer .gridComTabel.tableData {
  border: 1px solid #cccccc;
}

.corpCompMainContainer .tradeTableContainer .MuiBox-root {
  padding: 0 !important;
}

.corpCompMainContainer > .MuiGrid-container {
  display: block;
  height: 100%;
}

.corpCompMainContainer > .MuiGrid-container > .MuiGrid-grid-xs-true {
  height: 100%;
}

.tradeTableContainer {
  padding-left: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.tradeTableContainer .tradeTableLoader {
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.tradeTableContainer .recentTransactions {
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: auto;
}

.tradeTableContainer .recentTransactionsScroll {
  min-height: auto;
  height: 100%;
}

.tradeTableContainer .MuiDataGrid-root .MuiDataGrid-window::-webkit-scrollbar {
  width: 5px;
  height: 0px;
  transition: 300ms all ease;
  -webkit-transition: 300ms all ease;
  -moz-transition: 300ms all ease;
  -ms-transition: 300ms all ease;
  -o-transition: 300ms all ease;
}

.tradeTableContainer
  .MuiDataGrid-root
  .MuiDataGrid-window::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.tradeTableContainer
  .MuiDataGrid-root
  .MuiDataGrid-window::-webkit-scrollbar-thumb {
  background-image: linear-gradient(
    to right top,
    #2e385a,
    #5a4074,
    #91427d,
    #c54172,
    #ec4f56
  );
  outline: 1px solid slategrey;
}

.tradeTableContainer:hover
  .MuiDataGrid-root
  .MuiDataGrid-window::-webkit-scrollbar {
  height: 5px;
}

.tradeTableContainer .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.tradeTableContainer .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none;
}

.tradeTableContainer .MuiDataGrid-root .MuiDataGrid-row {
  cursor: pointer;
}

.tradeTableContainer .MuiDataGrid-root .MuiDataGrid-row:hover {
  background-image: linear-gradient(
    to right top,
    #dddddd,
    #ffffff,
    #ffffff,
    #ececec,
    #f4e3e4
  );
}

.tradeTableContainer .MuiDataGrid-root .MuiDataGrid-row {
  margin: 10px 0;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.tradeTableContainer .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
  font: normal normal 600 12px/18px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
}

.tradeTableContainer
  .MuiDataGrid-root
  .MuiDataGrid-row
  .MuiDataGrid-cell.MuiDataGrid-cell--textLeft {
  font: normal normal 12px/18px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
}

.tradeTableContainer
  .MuiDataGrid-root
  .MuiDataGrid-row
  .MuiDataGrid-cell.MuiDataGrid-cell--textLeft:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-left: 1px solid #cccccc;
  font: normal normal 12px/18px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
}

.tradeTableContainer
  .MuiDataGrid-root
  .MuiDataGrid-row
  .MuiDataGrid-cell.MuiDataGrid-cell--textLeft:nth-child(2) {
  font: normal normal 600 12px/18px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
}

.tradeTableContainer .MuiDataGrid-root .MuiDataGrid-row:first-child {
  margin-top: 0;
}

.tradeTableContainer .MuiDataGrid-root .MuiDataGrid-cell {
  border-bottom: 1px solid #cccccc;
  border-top: 1px solid #cccccc;
}

.tradeTableContainer .MuiDataGrid-root,
.tradeTableContainer .MuiDataGrid-root .MuiDataGrid-columnsContainer {
  border: 0;
}

.tradeTableContainer > .MuiTableContainer-root {
  overflow: initial;
}

.tradeTableContainer .MuiTableCell-paddingNone,
.tradeTableContainer .MuiTableCell-body {
  padding: 0 15px !important;
}

.tradeTableContainer .MuiTableCell-root {
  border-bottom: 1px solid #ccc !important;
}

.tradeTableContainer .MuiTableCell-alignLeft {
  white-space: nowrap;
  font: normal normal 600 12px/18px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
  opacity: 0.5;
}

.tradeTableContainer .MuiTableCell-body {
  font: normal normal 12px/18px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
  opacity: 1;
}

.tradeTableContainer .MuiTableCell-body .priceHistoryChart {
  display: inline-block;
}

.tradeTableContainer .MuiTableCell-body .priceHistoryChart .apexcharts-toolbar {
  display: none;
}

.tradeTableContainer
  .gridComTabel.tableData
  .MuiGrid-root.MuiGrid-item
  p.MuiTypography-root.MuiTypography-body1.MuiTypography-noWrap,
.tradeTableContainer
  .MuiGrid-root.tableDataImgMain.MuiGrid-item.MuiGrid-grid-xs-1 {
  font: normal normal 12px/18px Lexend;
  color: #2a324c;
}

.tradeTableContainer
  .gridComTabel.tableData
  .MuiGrid-root.MuiGrid-item
  p.MuiTypography-root.issuerName.MuiTypography-body1.MuiTypography-noWrap {
  font: normal normal 600 12px/18px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
}

.tradeTableContainer .recentTransactionsScroll {
  padding: 15px;
}

.tradeTableContainer {
  padding-left: 0;
}

.tradeTableContainer > .MuiGrid-root {
  height: 100%;
}

.tradeTableContainer::-webkit-scrollbar {
  width: 7px;
}

.tradeTableContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.tradeTableContainer::-webkit-scrollbar-thumb {
  background-color: #7569ee;
  outline: 1px solid slategrey;
}

.recentTransactions {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  min-height: 410px;
  padding: 10px 20px;
}

.recentTransactions
  .MuiGrid-root.gridComTabel.tableHade
  h5.MuiTypography-root.MuiTypography-h5.MuiTypography-noWrap {
  font: normal normal 600 14px/21px Lexend;
  max-width: 165px;
  letter-spacing: 0px;
  color: #2a324c;
  opacity: 0.5;
}

.recentTransactions
  .gridComTabel.tableData
  h5.MuiTypography-root.MuiTypography-h5.MuiTypography-noWrap {
  max-width: 165px;
  font: normal normal 14px/21px Lexend;
  letter-spacing: 0px;
  margin: 0;
}

.recentTransactions .gridComTabel.tableHade {
  margin: 10px 0;
}

.recentTransactions
  .gridComTabel.tableData
  h5.MuiTypography-root.boldText.MuiTypography-h5.MuiTypography-noWrap {
  font: normal normal bold 14px/21px Lexend !important;
  color: #2a324c !important;
}

.gridComTabel.tableData {
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 0 15px;
  border-radius: 12px;
  min-height: 60px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  animation: tableData 2.5s ease;
  -webkit-animation: tableData 2.5s ease;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  -o-transition: 0.3s ease;
}

.gridComTabel.tableData
  h5.MuiTypography-root.MuiTypography-h5.MuiTypography-noWrap {
  margin: 0;
  font: normal normal 14px/18px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
}

.gridComTabel.tableData
  h5.MuiTypography-root.unittext.MuiTypography-h5.MuiTypography-noWrap {
  font: normal normal 12px/18px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
  opacity: 0.5;
}

.gridComTabel.tableData
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-3
  h5.MuiTypography-root.MuiTypography-h5.MuiTypography-noWrap {
  max-width: 175px;
}

.gridComTabel.tableHade {
  margin-top: 10px;
  padding: 0 15px;
}

.MuiGrid-root.gridComTabel.tableData .tableDataImgMain {
  display: flex;
  align-items: center;
}

.tradeTableContainer
  .gridComTabel.tableData
  .MuiGrid-root.MuiGrid-item
  p.MuiTypography-root.MuiTypography-body1.MuiTypography-noWrap,
.tradeTableContainer
  .MuiGrid-root.tableDataImgMain.MuiGrid-item.MuiGrid-grid-xs-1 {
  font: normal normal 12px/18px Lexend;
  color: #2a324c;
}

.MuiGrid-root.gridComTabel.tableData .tableDataImg {
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
}

.profileEditDialogBox
  .MuiDialog-container.MuiDialog-scrollPaper
  .MuiPaper-elevation24 {
  box-shadow: none !important;
}

.profileEditDialogBox .MuiDialog-paperWidthSm {
  border: 10px solid #01010161;
  border-radius: 30px;
  width: 460px;
  height: 310px;
}

.profileEditDialogBox .MuiDialogContentText-root .dialogContentText {
  text-align: center;
  font: normal normal 14px/18px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
  overflow: visible !important;
}

.profileEditDialogBox .MuiDialogContentText-root .dialogContentText strong {
  font: normal normal 600 16px/21px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
  opacity: 1;
  padding: 5px 0 10px 0;
  display: block;
}

.profileEditDialogBox .MuiInputBase-input {
  height: inherit !important;
  font: normal normal 600 16px/21px Lexend !important;
  letter-spacing: 0px !important;
  color: #2a324c !important;
}

.profileEditDialogBox .MuiFormLabel-root {
  color: #2a324c !important;
  text-align: center;
  font: normal normal 14px/21px Lexend !important;
  letter-spacing: 0px !important;
}

.profileEditDialogBox .MuiInput-underline:before,
.profileEditDialogBox .MuiInput-underline:after {
  border-bottom: 1px solid #cfcfcf !important;
}

.profileEditDialogBox .MuiDialogContentText-root .dialogContentText p {
  margin: 0;
  opacity: 0.5;
  font: normal normal 14px/21px Lexend !important;
}

.profileEditDialogBox
  .MuiDialogContentText-root
  .dialogContentText
  p:first-child {
  padding: 20px 0 34px 0;
}

.profileEditDialogBox .MuiDialogContent-root:first-child {
  padding-top: 0;
}

.profileEditDialogBox .MuiDialogActions-root {
  padding: 8px 9px 10px;
}

.profileEditDialogBox .MuiDialogTitle-root .MuiIconButton-root {
  position: absolute;
  top: 2px;
  right: 2px;
}

.profileEditDialogBox h2.MuiTypography-root.MuiTypography-h6 {
  font: normal normal 600 14px/21px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
}

.profileEditDialogBox h2.MuiTypography-root.MuiTypography-h6 span.insiNum {
  color: #7569ee;
}

.profileEditDialogBox
  .MuiBox-root.MuiBox-root-16
  p.MuiTypography-root.MuiDialogContentText-root.MuiTypography-body1.MuiTypography-colorTextSecondary {
  font: normal normal 12px/21px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
  opacity: 0.5;
  text-align: center;
}

.profileEditDialogBox .getOtpNum input {
  text-align: center;
  width: 35px !important;
  height: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cfcfcf;
  margin-right: 20px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.profileEditDialogBox .getOtpNum input:last-child {
  margin-right: 0;
}

.profileEditDialogBox .getOtpNum input:focus-visible {
  background: #ffffff 0% 0% no-repeat padding-box;
  outline: 0;
}

.selectRelatShip {
  margin-bottom: 20px;
}

.selectRelatShip .MuiOutlinedInput-input {
  padding: 9px;
}

.selectRelatShip,
.selectRelatShip .MuiFormControl-root {
  width: 100%;
}

.chartCompSection {
  padding-left: 20px;
}

.chartCompSection .chartTitle {
  font: normal normal 600 14px/21px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
  opacity: 0.5;
  padding-left: 20px;
}

.portfolioAllocationValue .chartTitle {
  font: normal normal 600 14px/21px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
  opacity: 0.5;
  padding-left: 20px;
}

.smestDashboard,
.smestManageFunds,
.referAndEaenSec,
.helpAndSupport {
  padding: 20px 30px 30px;
}

.referAndEaenSec .MuiGrid-root.contactUsSec {
  position: absolute;
  bottom: 0;
}

.referAndEaenSec .MuiGrid-root.contactUsSec img {
  width: 100%;
}

.smestDashboard,
.smestManageFunds,
.referAndEaenSec,
.helpAndSupport {
  padding: 20px 25px 30px;
}

.loyaltyPointsRwd .referAndEaenBic {
  padding: 0;
}

.referAndEaenBic {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  padding: 26px 20px;
}

.referAndEaenBic.pointsHistory .gridComTabel.tableHade .MuiTypography-noWrap {
  font: normal normal 600 12px/18px Lexend;
  color: #2a324c;
  opacity: 0.5;
}

.referAndEaenBic {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  padding: 26px 20px;
}

.loyaltyPointsInfo {
  text-align: center;
}

.loyaltyPointsInfo h5.MuiTypography-root.MuiTypography-h5.MuiTypography-noWrap {
  font: normal normal bold 14px/21px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
}

.loyaltyPointsInfo h4.MuiTypography-root.MuiTypography-h4.MuiTypography-noWrap {
  font: normal normal 600 18px/21px Lexend;
  letter-spacing: 0px;
  color: #ec4f56;
}

.loyaltyPointsInfo small {
  font: normal normal medium 12px/18px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
  opacity: 0.5;
}

.loyaltyPointsInfo img {
  margin: 10px 0;
}

.loyaltyPoints {
  min-height: 150px;
}

.pointsHistory {
  min-height: 418px;
}

.notificationPopupSecBtn {
  margin-right: 40px;
}

.bellNotifiMe button.MuiButtonBase-root.MuiButton-root.MuiButton-text {
  border-radius: 50%;
  min-width: 0;
  padding: 12px;
}

li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
  transition: all 150ms linear;
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  -o-transition: all 150ms linear;
}

li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button:hover {
  color: #fff;
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%),
    0 7px 10px -5px rgb(156 39 176 / 40%);
  background-color: #ec4f56;
}

.MuiGrid-root.gridComTabel.tableData .tableDataImgMain {
  display: flex;
  align-items: center;
}

.MuiGrid-root.gridComTabel.tableData .tableDataImg {
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
}

.MuiGrid-root.gridComTabel.tableData .tableDataImg img {
  width: 100%;
  min-width: 33px;
}

.MuiGrid-root.gridComTabel.tableHade
  span.MuiButtonBase-root.MuiTableSortLabel-root,
.MuiGrid-root.gridComTabel.tableHade span.notSortingLabel {
  font: normal normal 600 12px/18px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 80%;
}

.tradeTableContainer
  .gridComTabel.tableData
  .MuiGrid-root.MuiGrid-item
  p.MuiTypography-root.MuiTypography-body1.MuiTypography-noWrap,
.tradeTableContainer
  .MuiGrid-root.tableDataImgMain.MuiGrid-item.MuiGrid-grid-xs-1 {
  font: normal normal 12px/18px Lexend;
  color: #2a324c;
}

.MuiGrid-root.gridComTabel.tableData
  span.MuiButtonBase-root.MuiTableSortLabel-root {
  font: normal normal 600 12px/18px Lexend;
  color: #2a324c;
}

.tradeTableContainer
  .gridComTabel.tableData
  .MuiGrid-root.MuiGrid-item
  p.MuiTypography-root.issuerName.MuiTypography-body1.MuiTypography-noWrap {
  font: normal normal 600 12px/18px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
}

span.sellYield {
  background: #ebe8ff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: #7569ee;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: 0.5s all linear;
}

.MuiGrid-root.gridComTabel.tableData:hover {
  box-shadow: 0px 6px 10px #2a324c26;
  transition: 0.5s all ease;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  -ms-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
}

.MuiGrid-root.gridComTabel.tableData:hover span.sellYield {
  border-radius: 4px;
  background: #7569ee 0% 0% no-repeat padding-box;
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%),
    0 7px 10px -5px rgb(156 39 176 / 40%);
  color: #ffffff;
}

.copyCodeArea {
  margin-top: 20px;
}

.copyCodeArea_whatsAppImg {
  width: 20px;
  height: 20px;
}

.MuiGrid-root.copyCodeArea
  button.MuiButtonBase-root.MuiButton-root.MuiButton-text {
  min-width: 0;
}

.copyCodeArea p.MuiTypography-root.MuiTypography-body1 {
  margin: 0;
  display: inline-block;
  font: normal normal 12px/18px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
}

.copyCodeArea .copyCodeinfo {
  border: 1px solid #ec4f5654;
  border-radius: 4px;
  padding: 10px 10px;
  font: normal normal 600 14px/21px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
  width: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copyCodeMainArea h5.MuiTypography-root.MuiTypography-h5.MuiTypography-noWrap {
  font: normal normal bold 14px/21px Lexend;
}

.copyCodeMainArea p.MuiTypography-root.MuiTypography-body1 {
  margin: 10px 0 20px;
  font: normal normal 12px/22px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
}

.copyCodeArea
  .copyCodeinfo
  button.MuiButtonBase-root.MuiButton-root.MuiButton-text {
  width: 0;
  padding: 0;
  min-width: 0;
  padding: 0px 10px 0px 20px;
}

.howToEarnPoints {
  min-height: 482px;
}

.pointsHistoryHead .MuiTypography-noWrap {
  font: normal normal bold 14px/21px Lexend;
  color: #2a324c;
}

.pointsHistoryHead .MuiButton-root {
  background: #ec4f56 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 10px #ec4f5633;
  border-radius: 18px;
  width: 88px;
  height: 29px;
}

.pointsHistoryHead .MuiButton-root:hover {
  background: #ec4f56 0% 0% no-repeat padding-box;
}

.pointsHistoryHead .MuiButton-label {
  font: normal normal 12px/21px Lexend;
  color: #ffffff;
}

.referAndEaenBic.pointsHistory .gridComTabel.tableHade .MuiTypography-noWrap {
  font: normal normal 600 12px/18px Lexend;
  color: #2a324c;
  opacity: 0.5;
}

.referAndEaenTableData .gridComTabel.tableData {
  border: 1px solid #cccccc;
  border-radius: 8px;
  margin-top: 5px;
}

.referAndEaenTableData
  .gridComTabel.tableData
  h5.MuiTypography-root.MuiTypography-h5.MuiTypography-noWrap {
  font: normal normal 12px/18px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
}

.referAndEaenTableData
  .gridComTabel.tableData
  h5.MuiTypography-root.boldText.MuiTypography-h5.MuiTypography-noWrap {
  font: normal normal bold 12px/18px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
}

.referAndEaenTableData
  .gridComTabel.tableData
  h5.MuiTypography-root.statusTextSuccess.MuiTypography-h5.MuiTypography-noWrap {
  font: normal normal 600 12px/18px Lexend;
  color: #26c76e;
}

.referAndEaenTableData
  .gridComTabel.tableData
  h5.MuiTypography-root.statusTextWarning.MuiTypography-h5.MuiTypography-noWrap {
  font: normal normal 600 12px/18px Lexend;
  color: #ec4f56;
}

.earnPoints {
  text-align: center;
}

.earnPoints span {
  background: #ebe8ff 0% 0% no-repeat padding-box;
  width: 50px;
  height: 50px;
  display: inline-block;
  border-radius: 50%;
}

.earnPoints p {
  font: normal normal 12px/18px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
  margin: 0;
}

.referAndEaenBic.howToEarnPoints
  h5.MuiTypography-root.earnPointsTitle.MuiTypography-h5.MuiTypography-noWrap {
  font: normal normal bold 14px/21px Lexend;
  color: #2a324c;
  margin-bottom: 20px;
  text-align: center;
}

.MuiDivider-root.divderClass {
  background-color: #cfcfcf;
  margin: 20px 0;
}

.redemption h5.MuiTypography-root.MuiTypography-h5.MuiTypography-noWrap {
  font: normal normal 14px/21px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
  text-align: center;
  margin-bottom: 10px;
}

.redemption
  h5.MuiTypography-root.MuiTypography-h5.MuiTypography-noWrap:last-child {
  margin-bottom: 0;
}

.redemption h5.MuiTypography-root.MuiTypography-h5.MuiTypography-noWrap span {
  color: #ec4f56;
  font: normal normal bold 14px/21px Lexend;
}

.contactUs {
  padding: 35px 40px 0;
}

.contactUs h5.MuiTypography-root.MuiTypography-h5.MuiTypography-noWrap {
  font: normal normal bold 16px/24px Lexend;
  letter-spacing: 0px;
  color: #ffffff;
}

.contactUs Button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font: normal normal 600 14px/18px Lexend;
  letter-spacing: 0px;
  color: #2a324c;
  height: 36px;
}

.contactUs Button:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
}

.contactUs p {
  font: normal normal 12px/18px Lexend;
  letter-spacing: 0px;
  color: #ffffff;
  margin: 0 0 10px;
}

.table-cell-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
